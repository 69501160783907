import React from 'react'
import { Accordion } from 'react-bootstrap'
const Faqs = () => {
   return (
      <div id="faqs" className='my-5 d-flex align-items-center justify-content-center  flex-column section-14 '>
         <h4 className="sec_title mb-5 text-center text-uppercase">
         FAQ's: Empowering Your Decision
         </h4>
         <div className="col-11 col-sm-9 col-md-7">
            <Accordion defaultActiveKey="0" >
               <Accordion.Item className='' eventKey="0">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">01 What types of units does Premia offer?
</h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Premia offers luxury 3 BHK flats and 4 BHK apartments in Jakkur, North Bangalore.
                  </Accordion.Body>
               </Accordion.Item >
               <Accordion.Item className='' eventKey="1">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                       02 Are these apartments for sale in Bangalore?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Absolutely! Premia presents 3 BHK luxury apartments for sale in the bustling city of Bangalore.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='' eventKey="2">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                        03 Are these 3 BHK apartments for sale?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Absolutely, Premia offers 3 BHK apartments for sale in Bangalore, combining luxury with an excellent investment opportunity.
                  </Accordion.Body>
               </Accordion.Item >
               <Accordion.Item className='' eventKey="3">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                        04 Why choose a 4 BHK apartment at Premia?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Experience opulence with spacious living in our exclusive 4 BHK apartments, redefining luxury living in Bangalore.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='' eventKey="4">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                        05 What makes Premia a standout choice for luxury apartments in Bangalore?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Premia offers lavish 3 & 4 BHK flats in Jakkur, North Bangalore, strategically located near Manyata Tech Park for a seamless work-life balance.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='' eventKey="5">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                      06 Is Premia suitable for investment purposes?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Premia's pre-launch status and proximity to Manyata Tech Park make it a lucrative option to buy an apartment in Bangalore
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='' eventKey="6">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                      07 Are there financing options available for these new properties in Bangalore?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Yes, Premia provides flexible financing options, making it easier for buyers to invest in the upcoming residential project in North Bangalore.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='' eventKey="7">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                      08 What sets Premia apart from other new properties in Bangalore?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  Premia stands out for its commitment to luxury and modern living, making it a top choice among new properties in Bangalore's thriving real estate market.
                  </Accordion.Body>
               </Accordion.Item>
               <Accordion.Item className='' eventKey="8">
                  <Accordion.Header>
                     <h6 className=" fq_font mb-0">
                      09 How can I explore more about the pre-launch projects and buy an apartment in Bangalore?
                     </h6>
                  </Accordion.Header>
                  <Accordion.Body className="text-start">
                  For information on buying an apartment in Bangalore at Premia, contact our sales team to discover exclusive pre-launch opportunities and secure your dream home.

                  </Accordion.Body>
               </Accordion.Item>

            </Accordion>
         </div>
      </div>
   )
}

export default Faqs