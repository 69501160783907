import React, { useState } from 'react'
import Header from './Header'
import Slider from './HomeSection/SliderSection'
import Space from './HomeSection/Space'
import FloorPlan from './HomeSection/FloorPlan'
import ChooseMe from './HomeSection/ChooseMe'
import Gallery from './HomeSection/Gallery'
import Brand from './HomeSection/Brand'
import Location from './HomeSection/Location'
import ClientsTestimonial from './HomeSection/ClientsTestimonial'
import Footer from './Footer'
import FormModal from './FormModal'
import 'react-medium-image-zoom/dist/styles.css'
import { ToastContainer } from 'react-toastify'
import Faqs from './HomeSection/Faqs'
import Aminities from './HomeSection/Aminities'
import Youtube from './HomeSection/Youtube'
import SiteForm from './HomeSection/SiteForm'
import FlorPlans from './HomeSection/FlorPlan2'
import Hero from './HomeSection/Hero'
import Button from './HomeSection/Button'
import RightButton from './HomeSection/RightButton'
const Home = () => {
   const [modalShow, setModalShow] = useState(false);

   const handleShow = () => {
      setModalShow(true)
   }

   return (
      <>
         <ToastContainer />
         <Header />
         <Slider />
         <Hero handleShow = {handleShow} />
         <Space handleShow={handleShow} />
         <Gallery />
         
         <FlorPlans  handleshow={handleShow}/>
         <ChooseMe />
         {/* <Youtube /> */}
         <Aminities />
         <SiteForm />
         <Location />
         <Brand />
         {/* <ClientsTestimonial /> */}
         <Faqs />
         <Button/>
         <RightButton/>
         <Footer />
         <FormModal modalShow={modalShow} setModalShow={setModalShow} />
        
      </>
   )
}

export default Home