import React, { useState, useEffect } from 'react'
import IMG4 from "../../Images/Aminities/img4.png"
import IMG1 from "../../Images/Aminities/img1.png"
import IMG2 from "../../Images/Aminities/img2.png"
import IMG3 from "../../Images/Aminities/img3.png"
import IMG5 from "../../Images/Aminities/img5.png"
import IMG6 from "../../Images/Aminities/img6.png"
import IMG7 from "../../Images/Aminities/img7.png"
import IMG8 from "../../Images/Aminities/img8.png"
import IMG9 from "../../Images/Aminities/img9.png"
import IMG10 from "../../Images/Aminities/img10.png"
import IMG11 from "../../Images/Aminities/img11.png"
import IMG12 from "../../Images/Aminities/img12.png"
import IMG13 from "../../Images/Aminities/img13.png"
import IMG14 from "../../Images/Aminities/img14.png"
import IMG15 from "../../Images/Aminities/img15.png"
import IMG16 from "../../Images/Aminities/img16.png"
const Aminities = () => {
    const cardData = [
        {
            title: 'Car Parking',
            url: IMG1
        },
        {
            title: 'Club House',
            url: IMG2
        },
        {
            title: 'Sports Area',
            url: IMG3
        },
        {
            title: 'Gymnasium',
            url: IMG4
        },

        {
            title: 'Indoor Games',
            url: IMG5
        },
        {
            title: 'Basketball Pole',
            url: IMG6
        },
        {
            title: 'Creche',
            url: IMG7
        },
        {
            title: 'EV charging Point',
            url: IMG8
        },
        {
            title: 'Snooker',
            url: IMG9
        },
        {
            title: 'Party Hall',
            url: IMG10
        },
        {
            title: 'Rooftop Sky Deck',
            url: IMG11
        },
        {
            title: 'Cigar Lounge',
            url: IMG12
        },

        {
            title: 'Garbage Chute',
            url: IMG13
        },
        {
            title: 'Mini Golf',
            url: IMG14
        },
        {
            title: 'Play Area',
            url: IMG15
        },
        {
            title: 'Infinity Pool',
            url: IMG16
        },

    ]
    const [count, setCount] = useState(0);
    const [{ items }, setItems] = useState({ items: [] });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };

    }, []);
    useEffect(() => {
        if (items == '')
            addItem();
    }, [])
    const addItem = () => {
        var respCount = windowWidth > 768 ? 8 : 4;
        var cardLength = count + respCount;

        if (count == cardData.length) return;
        for (var i = count; i < cardLength; i++) {
            items.push(
                <div key={i} className=" col-lg-3 col-sm-5 col-md-5 col-5 mb-4 center-block">
                    <div className="card aminities_card" >
                        <img className='col-4' src={cardData[i].url} alt="test" />
                        <h5 className='card_h5 text-center pt-4'>{cardData[i].title}</h5>
                    </div>
                </div>
            )
        }
        setItems({ items: [...items] });
        setCount(cardLength);
    };

    return (
        <div id="amenities" className=' pb-0  justify-content-center' style={{ paddingTop: '50px' }}>
            <h4 className='mb-4  amenities_title text-center'> Amenities</h4>
            <div className="col-12 col-lg-8  offset-lg-2 offset-1 row">
                {items}
            </div>
            <div class="col text-center">
                <button className=' know_btn mb-5' onClick={addItem} >Load More</button>
            </div>
        </div>
    )
}

export default Aminities