// PhoneNumberInput.js

import React from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const PhoneNumberInput = () => {
  return (
    <div>
      <label htmlFor="phoneNumber">Phone Number:</label>
      <PhoneInput
        country={'in'}
        inputProps={{
          name: 'phoneNumber',
          required: true,
        }}
      />
    </div>
  );
};

export default PhoneNumberInput;
