import React, { useState } from 'react'
import IMG1 from "../../Images/FloorPlans/1.png"
import IMG2 from "../../Images/FloorPlans/2.png"
import IMG3 from "../../Images/FloorPlans/3.png"


import { Tabs, Tab, Image } from 'react-bootstrap';

const FlorPlans = ({handleshow}) => {
  const [key, setKey] = useState('tab1');


  return (
    <div id='floorPlans' className='floors_main'>
      <div className=" container floors_detail sec_title">
        <h4 className='text-center'>
        Unfold Your Story in Every Square Foot
          {/* <br /> <span> <i><b>
            Your Grand Living Canvas
          </b></i></span> */}
        </h4>
        <p>Take a glimpse of our wonderful 3 & 4 BHK apartments and get an idea of what your future homes would look like</p>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="tab1" title="Typical Floor Plan">
            <div className="tab_content">
              <Image src={IMG1} alt="Image 1" fluid />
            </div>
          </Tab>

          <Tab eventKey="tab2" title="Typical Floor Plan">
            <div className="tab_content">
              <Image src={IMG2} alt="Image 2" fluid />
            </div>
          </Tab>

          <Tab eventKey="tab3" title="15th Floor Plan">
            <div className="tab_content">
              <Image src={IMG3} alt="Image 3" fluid />
            </div>
          </Tab>
          
        </Tabs>
        <button className="know_btn mb-5" onClick={handleshow}>Explore More</button>

      </div>
    </div>
  )
}

export default FlorPlans