import React from 'react'
import { Table } from 'react-bootstrap'

const Space = ({ handleShow }) => {
   return (
      <div className=" bg-light" style={{padding : '22px'}}>
         <div id="configuration" className='d-flex align-items-center justify-content-center px-0 px-sm-5 mx-3 mx-md-5 section-4 '>
            <h4 className='sec_title mb-5 mt-0 mt-md-5 text-center'>
               Legacy Defined with Space
            </h4>

            {/* <div className="col-12 col-sm-9  col-xl-8 py-0 py-md-4 m-auto price-box">
               <Table className=''  bordered >
                  <thead className='thead'>
                     <tr>
                        <th>Configuration</th>
                        <th>SBA (Sq.Ft.)</th>
                        <th>Price</th>
                     </tr>
                  </thead>
                  <tbody >
                     <tr style={{backgroundColor:'rgba(234, 233, 232, 1)'}}>
                        <td><p className='mb-0'>3 BHK</p></td>
                        <td><p className='mb-0'>1571 - 3411 sqft</p></td>
                        <td><div className='get-price-btn'>Get Pricing</div></td>
                     </tr>
                     <tr>
                        <td><p className='mb-0'>4 BHK</p>
                        {/* <div className='custom-badge'>Bestseller</div> */}
                        {/* </td>
                        <td><p className='mb-0'>1571 - 3411 sqft</p></td>
                        <td ><div className='get-price-btn'>Get Pricing</div></td>
                     </tr>
                  </tbody>
               </Table>
            </div> */} 


<div className="container2">
      <div className="box2">
        <div className="text2-co">Configuration</div>
        <div className="text2-bhk">3 BHK</div>
        <div className="text2-sz">Size</div>
        <div className="text2-sq">1776 - 1813 sqft</div>
        <div className='get-price-btn' onClick={handleShow}>Get Pricing</div>
      </div>
      <div className="box2">
        <div className="text2-co">Configuration</div>
        <div className="text2-bhk">4 BHK</div>
        <div className="text2-sz">Size</div>
        <div className="text2-sq">2264 - 2783 sqft</div>
        <div className='get-price-btn' onClick={handleShow}>Get Pricing</div>
      </div>
      <div className="box2">
        <div className="text2-co">Configuration</div>
        <div className="text2-bhk">Duplex</div>
        <div className="text2-sz">Size</div>
        <div className="text2-sq">3411 sqft</div>
        <div className='get-price-btn' onClick={handleShow}>Get Pricing</div>
      </div>
    </div>
         </div>
      </div>
   )
}

export default Space
