import React from "react";
import msg from "../Images/message.png";
export default function ConfirmationPage() {
   return (
      <div className="confirm-page-container">
         <h2 className="text-center">THANK YOU FOR REACHING OUT TO US!</h2>
         <img alt="msg-img" src={msg} className="confirm-msg" />
         <p className="text-center">
            Your message has been received. <br /> We'll be in touch with you shortly to address your inquiry and help you discover the epitome of grand living.
         </p>
         <a href='' target="_blank" className="confirm-btn mt-3" download="Brochure-SohanExotica">Download Brochure</a>
      </div>
   );
}