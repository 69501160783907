import './App.css';
import './style/style.css'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './Component/Home';
import PrivacyPolicy from './Component/PrivacyPolicy';
import ConfirmationPage from './Component/ThankYou';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/thank-you' element={<ConfirmationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
