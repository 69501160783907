import school from "../../Images/Location/school.png"
import mall from "../../Images/Location/mall.png"
import markets from "../../Images/Location/markets.png"
import it from "../../Images/Location/it.png"
import maps from "../../Images/Location/maps.png"
import hospital from "../../Images/Location/hospital.png"
import React, { useEffect, useState } from "react";



const Location = () => {
   const cardData = [
      {
         title: 'Educational Institutions',
         url: school
         
      },
      {
         title: 'Shopping Complex',
         url: mall
      },
      {
         title: 'Restaurants & Cafes',
         url: markets
      },


   ]
   const cardData1 = [
      {
         title: 'IT Hubs',
         url: it
      },
      {
         title: 'Landmarks',
         url: maps
      },
      {
         title: 'Hospitals',
         url: hospital
      },


   ]

   const cardData3 = cardData.concat(cardData1)
   const [expandedCard, setExpandedCard] = useState(null);

   const handleToggle = (title) => {
      if (expandedCard === title) {
         // If the clicked card is already expanded, collapse it
         setExpandedCard(null);
         setHoveredCard(null);
       } else {
         // If the clicked card is not expanded, expand it and set hoveredCard
         setExpandedCard(title);
         setHoveredCard(title);
       }
   };
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   const [hoveredCard, setHoveredCard] = useState(null);
   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);
   return (
      <div id="location" className='my-5 d-flex align-items-center justify-content-center px-3 px-sm-5 flex-column section-12'>

         <h4 className=' sec_title mb-5 text-center'>
         Latitude of Luxury, Longitude of Lifestyle
         </h4>
         {windowWidth >= 768 ? (
            <div className=" px-0 px-md-4 flex-column flex-sm-row d-flex col-12 " id="location_down">
               <div className="col-12 mb-5 mb-sm-0 col-sm-3 px-2">
                  {/* <Accordion defaultActiveKey="0" flush>

                  <Accordion.Item className='mb-3' eventKey="0">
                     <img src={school} width={'20%'} className="img" alt="img1"></img>
                     <Accordion.Header>
                        <h6 className=" mb-0">Schools</h6>
                     </Accordion.Header>

                  </Accordion.Item >

                  <Accordion.Item className='mb-3' eventKey="1">
                     <img src={mall} width={'20%'} className="img" alt="img1"></img>
                     <Accordion.Header>
                        <h6 className=" mb-0">
                           Malls
                        </h6>
                     </Accordion.Header>
                  </Accordion.Item>

                  <Accordion.Item className='mb-3' eventKey="2">
                     <img src={markets} width={'20%'} className="img" alt="img1"></img>
                     <Accordion.Header>
                        <h6 className=" mb-0">
                           Markets
                        </h6>
                     </Accordion.Header>

                  </Accordion.Item>
               </Accordion> */}
                  <div id="amenities" className='travel_main pb-0'>
                     <div className="travel_card_main">
                        {
                           cardData.map((elm, index) => {
                              return (
                                 <div className="amenities_card_detail" onMouseEnter={() => setHoveredCard(elm.title)}
                                 onMouseLeave={() => setHoveredCard(null)}>
                                    <img className='col-12' src={elm.url} alt="test" />
                                    <h5 className='card_h5'>{elm.title}</h5>
                                   
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
                  
               </div>
               <div className="col-12 col-sm-6  border border-3 border-white relative-pos">
                  
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4166183670095!2d77.6187787!3d13.072761799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19be1f4f7ae3%3A0xc6bc495cc2abe06d!2sUnishire%20Belvedere%20Premia!5e0!3m2!1sen!2sin!4v1714495504745!5m2!1sen!2sin"
                   width="100%" height="100%"  style={{ border: "0px", margin: "0px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                   {hoveredCard != null  && (
              <div className="additional_details">
               <ul>
                  {
                     hoveredCard === 'IT Hubs' && (
                        <>
                     <li>  Manyata Tech park - 2.2 Km</li>
                     <li>Bhartiya City Tech Park - 4.5 Km</li>
                     <li>BEL Corporate Office - 5.1 Km</li>
                     <li>Brigade magnum - 5.5 Km</li>
                     <li>L & T Tech Park - 5.9 Km</li>
                     <li>Kirloskar Business Park -6 Km</li>

                        </>
                     )
                  }
                   {
                     hoveredCard === 'Educational Institutions' && (
                        <>
                      <li> Nafl, Jakkur - 1.4 Km</li> 
                      <li>VIBGYOR High School - 1.8 Km</li> 
                      <li>Manipal Academy of Higher Education - 4 Km</li> 
                      <li>Vidya Niketan School - 6.7 Km</li> 
                      <li>Reva Institute of Technology & Management - 7.2 Km</li> 

                        </>
                     )
                  }
                   {
                     hoveredCard === 'Hospitals' && (
                        <>
                     <li> Regal Hospital - 3.6 Km</li> 
                     <li>Prolife Multispeciality Hospital - 4.5 Km</li> 
                     <li>Rainbow Children Hospital - 4.5 Km</li> 
                     <li>Aster CMI Hospital - 5.8 Km</li> 
                     <li>Manipal hospital - 6.3 Km</li> 
                     <li>Baptist Hospital - 8.6 Km</li> 

                        </>
                     )
                  }
                  {
                     hoveredCard === 'Shopping Complex' && (
                        <>
                 <li>  Elements Mall - 4.1 Km</li> 
                 <li> Bhartiya Mall Of Bengaluru - 4.7 Km</li> 
                 <li> Phoenix Mall Of Asia - 5.9 Km</li> 
                 <li> Esteem Mall - 6.2 Km</li> 
                 <li> The Galleria Mall - 6.4 Km</li> 

                        </>
                     )
                  }
                  {
                     hoveredCard === 'Restaurants & Cafes' && (
                        <>
                 <li>  BYG cafe & restaurant- 1.2 Km</li> 
                 <li> The Daily Bean- 3.5 Km</li> 
                 <li> Hebbal Social- 5.6 Km</li> 
                 <li> Barleys & Grapes- 6.4 Km</li> 


                        </>
                     )
                  }
                  {
                     hoveredCard === 'Landmarks' && (
                        <>
                    <li>Chokkanahalli Lake - 2.9 Km</li> 
                    <li>Jakkur Lake - 3.1 Km</li> 
                    <li>Yelahanka Air Force Base - 6.7 Km</li> 
                    <li>Hebbal - 7 Km</li> 
                    <li>Mg Road - 14.6 Km</li> 
                    <li>Kempegowda International Airport - 24.8 Km</li> 


                        </>
                     )
                  }
                  
               </ul>
              </div>
            )}

               </div>
               <div className="col-12 mb-5 mb-sm-0 col-sm-3 px-2">
                  <div id="amenities" className='travel_main pb-0'>
                     <div className="travel_card_main">
                        {
                           cardData1.map((elm, index) => {
                              return (
                                 <div className="amenities_card_detail" onMouseEnter={() => setHoveredCard(elm.title)}
                                 onMouseLeave={() => setHoveredCard(null)}>
                                    <img className='col-12' src={elm.url} alt="test" />
                                    <h5 className='card_h5'>{elm.title}</h5>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               </div>
            </div>
         ) : (

            <div className="  col-12 " id="">
               <div className="col-12 mb-5 mb-sm-0 col-sm-3 px-2">
                  <div id="amenities" className=' pb-0'>
                     <div className="">
                        {
                           cardData3.map((elm, index) => {
                              return (
                                 <div className="card my-3 col-12  location_card" >
                                    <div className="row d-flex align-self-start">
                                       <img className='col-4 ' src={elm.url} alt="test" />
                                       <h5 className='card_h5 col-6 '>{elm.title}</h5>
                                       <button className="col-2 arrow-btn" onClick={() => handleToggle(elm.title)}>
                                       {expandedCard=== elm.title ? '▲' : '▼'}

        </button>
        {expandedCard=== elm.title && (
              <div className="details" style={{
               marginTop:"20px"
             }}>
               <ul>
                  {
                     hoveredCard === 'IT Hubs' && (
                        <>
                     <li>  Manyata Tech park - 2.2 Km</li>
                     <li>Bhartiya City Tech Park - 4.5 Km</li>
                     <li>BEL Corporate Office - 5.1 Km</li>
                     <li>Brigade magnum - 5.5 Km</li>
                     <li>L & T Tech Park - 5.9 Km</li>
                     <li>Kirloskar Business Park -6 Km</li>

                        </>
                     )
                  }
                   {
                     hoveredCard === 'Educational Institutions' && (
                        <>
                      <li> Nafl, Jakkur - 1.4 Km</li> 
                      <li>VIBGYOR High School - 1.8 Km</li> 
                      <li>Manipal Academy of Higher Education - 4 Km</li> 
                      <li>Vidya Niketan School - 6.7 Km</li> 
                      <li>Reva Institute of Technology & Management - 7.2 Km</li> 

                        </>
                     )
                  }
                   {
                     hoveredCard === 'Hospitals' && (
                        <>
                     <li> Regal Hospital - 3.6 Km</li> 
                     <li>Prolife Multispeciality Hospital - 4.5 Km</li> 
                     <li>Rainbow Children Hospital - 4.5 Km</li> 
                     <li>Aster CMI Hospital - 5.8 Km</li> 
                     <li>Manipal hospital - 6.3 Km</li> 
                     <li>Baptist Hospital - 8.6 Km</li> 

                        </>
                     )
                  }
                  {
                     hoveredCard === 'Shopping Complex' && (
                        <>
                 <li>  Elements Mall - 4.1 Km</li> 
                 <li> Bhartiya Mall Of Bengaluru - 4.7 Km</li> 
                 <li> Phoenix Mall Of Asia - 5.9 Km</li> 
                 <li> Esteem Mall - 6.2 Km</li> 
                 <li> The Galleria Mall - 6.4 Km</li> 

                        </>
                     )
                  }
                  {
                     hoveredCard === 'Restaurants & Cafes' && (
                        <>
                 <li>  BYG cafe & restaurant- 1.2 Km</li> 
                 <li> The Daily Bean- 3.5 Km</li> 
                 <li> Hebbal Social- 5.6 Km</li> 
                 <li> Barleys & Grapes- 6.4 Km</li> 


                        </>
                     )
                  }
                  {
                     hoveredCard === 'Landmarks' && (
                        <>
                    <li>Chokkanahalli Lake - 2.9 Km</li> 
                    <li>Jakkur Lake - 3.1 Km</li> 
                    <li>Yelahanka Air Force Base - 6.7 Km</li> 
                    <li>Hebbal - 7 Km</li> 
                    <li>Mg Road - 14.6 Km</li> 
                    <li>Kempegowda International Airport - 24.8 Km</li> 


                        </>
                     )
                  }
                  
               </ul>
              </div>
            )}
                                    </div>
                                   
                                    

                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               </div>

               <div className="col-12 mb-5 px-2 ">
                  <div className="border border-3 border-white">
                     <iframe
                        src="https://www.google.com/maps/embed?"
                        width="100%"
                        height="100%"
                        style={{ border: "0px", margin: "0px" }}
                        allowfullscreen={true}
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                     ></iframe>
                  </div>
               </div>

            </div>
         )}
      </div>
   )
}

export default Location
