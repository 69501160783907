import React from "react";
import Imgbg from '../../Images/hero/3.png'
import Imgbg2 from '../../Images/hero/Rectangl.png'

const Hero = ({handleShow}) => {
    return (
        <div className="demo_main" id="overview">
            <div className="container demo_content ">
                <div className="content_first">
                    <div className="left-panel">
                        <div className="heads">
                            <h2>Welcome to Premia</h2>
                        </div>
                        <div className="para my-4">
                        Experience a seamless blend of legacy and luxury Premia, crafted by SA Lifestyle in the vibrant Jakkur neighborhood! Unveil the charm of G+17 High-Rise Living offering  3 & 4 BHK luxury residences, ranging from 1571 to 3411 sq.ft. Your lavish abode awaits in the heart of North Bangalore!

                        </div>
                        <button className="know_btn" onClick={handleShow}>Explore More</button>
                    </div>
                    <div className="right-panel">
                        <div className=" position-relative text-end">
                            <div className="first_img">
                                <img
                                    className="img_first"
                                    src={Imgbg2}
                                    alt=""
                                />
                            </div>
                            <div className="sec_img">
                                <img
                                    className="img_sec"
                                    src={Imgbg}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="catalog row">
                    <div className="cat_detail col-lg-2 col-6 col-sm-6">
                            <p>1.13<span>Acre</span></p>
                        <div className="sub_title">land area</div>
                    </div>

                    <div className="cat_detail col-lg-3 col-6 col-sm-6">
                        <p>3&4<span>BHK</span></p>
                        <div className="sub_title">configuration</div>
                    </div>

                    <div className="cat_detail col-12 col-lg-3 col-sm-12">
                        <p>1766-3411<span>sq.ft</span></p>
                        <div className="sub_title">size range</div>
                    </div>

                    <div className="cat_detail col-lg-2 col-6 col-sm-6">
                        <p>78<span>Units</span></p>
                        <div className="sub_title">Available</div>
                    </div>

                    <div className="cat_detail col-lg-2 col-6 col-sm-6">
                        <p>Jakkur</p>
                        <div className="sub_title">Location</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Hero;
