import React, { useEffect, useState } from 'react'
import Image4 from "../../Images/brand.png"
import Image2 from "../../Images/brand-resp.png"

const Brand = () => {
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);
   return (
      <>
         {windowWidth >= 768 ? (
            <img src={Image4} alt="poster" width={'100%'} />
         ) : (
            <img src={Image2} alt="poster" width={'100%'} />
         )}
      </>
   )
}

export default Brand