import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import axios from "axios";
import Logo from "../Images/Logo/logo 1.png";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from 'react-phone-number-input/input'
import { toast } from "react-toastify";

function MyVerticallyCenteredModal(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    configuration: "",
  });
  const [value, setValue] = useState();
  // console.log(form);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log(value.substring(1),"sub");
    const naya=value?.substring(1);
    console.log(naya,"naya");
    if (naya.length < 9) {
      toast.error("Please Enter a valid Phone Number");
      return;
    }
    setLoading(true);
    const data = {
      email: form.email,
      name: form.name,
      phone: naya,
      configuration: form.configuration,
    };
    const name = form.name.split(" ");
    const data2 = [
      {
        Attribute: "FirstName",
        Value: name[0],
        Fields: null,
      },
      {
        Attribute: "LastName",
        Value: name[1],
        Fields: null,
      },
      {
        Attribute: "EmailAddress",
        Value: form.email,
        Fields: null,
      },
      {
        Attribute: "Phone",
        Value: naya,
        Fields: null,
      },
      {
        Attribute: "mx_Projects_Name",
        Value: "Premia",
      },
      {
        Attribute: "Source",
        Value: "Google Ads",
      },
    ];
    try {
      const response = await axios.post(
        "https://api.aspireprop.com/cn/addSohanEnquiry",
        data
      );
      const response2 = await axios.post(
        "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r090d222f670409601d84d6a12987ce40&secretKey=aa4c6dd7b5d812f03c24f57cb79678f102cd55f7",
        data2
      );
      // console.log("Enquiry submitted:", response.data);
      setForm({
        email: "",
        name: "",
        phone: "",
        configuration: "",
      });
      setValue();
      navigate("/thank-you");
    } catch (err) {
      alert("Please Try Again");
    }
    setLoading(false);
  };
  // console.log(value);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="col-12"
          id="contained-modal-title-vcenter modal-title"
        >
          <img
            style={{ height: "70px" }}
            src={Logo}
            className="logo-img-modal"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mx-3 modal-form" onSubmit={handleFormSubmit}>
          <div className="row ">
            <div className="col-12 my-3">
              <input
                className="input w-100 px-2"
                type="text"
                required
                placeholder="Name"
                value={form.name}
                onChange={handleFormChange}
                name="name"
              />
            </div>
            <div className="col-12">
              <input
                className="input w-100 px-2"
                type="email"
                required
                placeholder="Email"
                value={form.email}
                onChange={handleFormChange}
                name="email"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <PhoneInput
                required
                className="input w-100 phone-input"
                inputProps={{
                  name: "phone",
                  required: true,
                  // autoFocus: true
                }}
                placeholder="Enter phone number"
                value={value?.substring(1)}
                onChange={(e) => setValue(e)}
              />
              {/* <PhoneInput
                required
                className="input w-100 phone-input"
                inputProps={{
                  name: 'phone',
                  required: true,
                  // autoFocus: true
                }}
                country={'in'}
                value={value}
                onChange={(e) => setValue(e)}
              // onChange={(e) => setValue(e.target.value)}
              /> */}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <select
                className="input w-100 px-2"
                type="text"
                required
                placeholder="Enter a message.."
                // value={form.message}
                onChange={handleFormChange}
                name="configuration"
              >
                <option selected disabled value="">
                  Select Configuration
                </option>
                <option value="3 BHK">3 BHK</option>
                <option value="4 BHK">4 BHK</option>
              </select>
            </div>
          </div>
          <hr />
          <div
            className="d-flex justify-content-center modal-cont-ftr"
            type="submit"
          >
            <button
              disabled={loading}
              className="btn-yellow btn-black text-center modal-btn"
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const FormModal = ({ modalShow, setModalShow }) => {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default FormModal;
