import React from 'react'
import { IoCall } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";

const Button = () => {

const handleCall = () => {
    window.location.href = 'tel:+91 9886660229'
}
const handleWtsp = () => {
    const phoneNumber = '9886660229'; 
    const message = 'Hello, I would like to know more about your services.'; // Optional message
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
}
  return (
    <div className="button-container">
      <button className="call-now" onClick={handleCall}><IoCall className='icon-but'/>Call Now</button>
      <button className="whatsapp" onClick={handleWtsp}> <FaWhatsapp className='icon-but'/>WhatsApp</button>
    </div>
  )
}

export default Button