import React from 'react'
import { FiDownload } from "react-icons/fi";
const RightButton = () => {
  return (
    <div className="right-middle-button">
      <button>
        
      Download Brochure
      <FiDownload className="icon-b" />
      </button>
    </div>
  )
}

export default RightButton