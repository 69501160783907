import React from 'react'
import '../../style/section_six.css'
import IMG4 from "../../Images/ChooseMe/balecoins.png"
import IMG5 from "../../Images/ChooseMe/premium.png"
import IMG1 from "../../Images/ChooseMe/privacy.png"
import IMG2 from "../../Images/ChooseMe/roof.png"
import IMG3 from "../../Images/ChooseMe/tieups.png"

const ChooseMe = () => {

  const cardData = [
    {
      title: 'Elite Privacy Living',
      detail: 'Elevate your privacy at Premia with only 5 units per floor and dedicated lifts for effortless mobility. ',
      url: IMG1
    },
    {
      title: 'Skyline Oasis: Rooftop Infinity Pool  ',
      detail: "Dive into luxury at Premia's wave-like rooftop infinity pool, a daily enchantment overlooking the city's skyline",
      url: IMG2
    },
    {
      title: 'World-Class Home Brands',
      detail: "Discover prestigious brands like Jaquar, Anchor Roma, Artize, and more adorning your home with premium materials and craftsmanship",
      url: IMG3
    },
    {
      title: 'Expansive Balcony Retreats',
      detail: "Embrace extended comfort with spacious balconies attached to living rooms, a signature feature of the lifestyle at Premia.",
      url: IMG4
    },
    {
      title: 'Prime Connectivity Hub',
      detail: "It takes 20 minutes to reach Bangalore International Airport via NH-7 or Airport road, while Tech Park Road to Manyata takes 2-3 minutes.",
      url: IMG5
    }
  ]

  return (
    <div className='choose_section_head' id='about'>
      <h4 className='sec_title'>5 Reasons to Choose premia</h4>
      <div className="card_main row">
        {
          cardData.map((elm, index) => {
            return (
              <div className="card_detail col-5 col-lg-2">
                <div className="">
                  <img className='col-12' src={elm.url} alt="test" />
                </div>
                <h4>{elm.title}</h4>
                <p>{elm.detail}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default ChooseMe