import React, { useEffect, useState } from "react";
import Image1 from "../../Images/Banner/poster-home1.png"
import Image2 from "../../Images/Banner/poster-home2.png"
import Image3 from "../../Images/Banner/poster-home3.png"
import Image4 from "../../Images/hero/111.png"
import Home1 from "../../Images/home-1.png"
import Home2 from "../../Images/home-2.png"
import Home3 from "../../Images/hero/2.png"
import img from "../../Images/hero/1.png"
import img1 from "../../Images/hero/11.png"
import Slider from "react-slick";
import PreArrrow from '../../Images/Banner/prev-arrow.png'
import NextArrrow from '../../Images/Banner/next-arrow.png'
import { FaDownload } from "react-icons/fa6";

const CustomPrevArrow = (props) => (
  <div
     {...props}
     style={{
        ...props.style,
        left: '30px',
        zIndex: 1,
        borderRadius: '50%',
        // padding: '10px',
        cursor: 'pointer'
     }}
  >
     <img style={{ maxWidth: '45px' }} src={PreArrrow} alt="" />
  </div>
);

// Custom Arrow Component
const CustomNextArrow = (props) => (
  <div
     {...props}
     style={{
        ...props.style,
        right: '-30px',
        zIndex: 1,
        borderRadius: '50%',
        //  padding: '10px', 
        cursor: 'pointer'
     }}
  >
     <img style={{ maxWidth: '45px' }} src={NextArrrow} alt="" />
  </div>
);

const SliderSection = () => {
  
  const [settings, setSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomPrevArrow direction="prev" />,
    nextArrow: <CustomNextArrow direction="next" />,
  })
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className=" home-sec-1 ">
        <Slider {...settings}>

          <div className="home-img">
            {windowWidth >= 768 ? (
              <img src={img} alt="poster" className=" home-window-img" />
            ) : (
              <img src={img1} alt="poster" className=" home-mobile-img" />
            )}
          </div>
          <div className="home-img">
            {windowWidth >= 768 ? (
              <img src={Image2} alt="poster" className=" home-window-img" />
            ) : (
              <img src={Image4} alt="poster" className=" home-mobile-img" />
            )}
          </div>
        </Slider>
       {/* <button id="scroll-btn" className="know_btn btn-rotate mb-5" title="Download Brochure">Download Brochure</button> */}
      </div>
      <div className="home-sec-2 home_boxes row" style={{position: 'relative',background:'#fff' }}>
        <div className='sec-2-1 col-3 col-sm-2' style={{background:'#eae9e8'}}>
          <img src={Home1}  className="img" alt="img1"></img>
          <h5>Luxury High Rise Living</h5>
          <p>Elevate your lifestyle with 17 floors of premium living, offering uninterrupted city views for each residence.</p>
        </div>
        <div className='sec-2-1 col-3 col-sm-2' style={{background:'#eae9e8'}}>
          <img src={Home2}  className="img" alt="img1"></img>
          <h5>Exclusive Residences</h5>
          <p>Limited to 5 units per floor, ensuring privacy, with 3 dedicated lifts for seamless access to 68 families</p>
        </div>
        <div className='sec-2-1 col-3 col-sm-2' style={{background:'#eae9e8'}}>
          <img src={Home3}  className="img" alt="img1"></img>
          <h5>Architectural Balance</h5>
          <p>Achieve balance with 82% open space, creating a serene environment through thoughtful design with only 18% building blueprint.</p>
        </div>
    
      </div>
    </>
  )
}

export default SliderSection