import React, { useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { Offcanvas } from 'react-bootstrap'
import Logo from "../Images/Logo/logo 1.png"
const Header = () => {
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const toggleShow = () => setShow((s) => !s);
   return (
      <header className='header'>
         <div className='col-3'>
            <img className='logo'  src={Logo} alt="logo" />
         </div>
         <div className='d-none d-lg-flex'>
            <ul className="header-routes">
            <a href="#overview">Overview</a>
                  <a href="#about">Key Features</a>
                  <a href="#amenities">Amenities</a>
                  <a href="#gallery">Gallery</a>
                  <a href="#configuration">Configuration</a>
                  <a href="#floorPlans">Floor Plans</a>
                  <a href="#location">Location</a>
                  <a href="#faqs">FAQ's</a>
            </ul>
         </div>
         <div className=' d-flex justify-content-end pe-3 d-lg-none'>
            <div onClick={() => toggleShow()} type="submit"><AiOutlineMenu /></div>
         </div>
         <Offcanvas show={show} onHide={handleClose} backdrop={true} scroll={true} placement="end">
            <Offcanvas.Header closeButton>
               <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
               <ul onClick={() => { setShow(false) }} className="header-routes-canvas text-start d-flex flex-column">
                  <a href="#overview">Overview</a>
                  <a href="#about">Key Features</a>
                  <a href="#amenities">Amenities</a>
                  <a href="#gallery">Gallery</a>
                  <a href="#configuration">Configuration</a>
                  <a href="#floorPlans">Floor Plans</a>
                  <a href="#location">Location</a>
                  <a href="#faqs">FAQ's</a>
               </ul>
            </Offcanvas.Body>
         </Offcanvas>

      </header>
   )
}

export default Header