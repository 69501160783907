import React, { useState, useEffect } from 'react'
import img1 from "../../Images/Gallery/1.png"
import img2 from "../../Images/Gallery/2.png"
import img3 from "../../Images/Gallery/3.png"
import img4 from "../../Images/Gallery/4.png"
import img5 from "../../Images/Gallery/5.png"
import img6 from "../../Images/Gallery/6.png"
import img7 from "../../Images/Gallery/7.png"
import img8 from "../../Images/Gallery/8.png"
import img9 from "../../Images/Gallery/9.png"
import img10 from "../../Images/Gallery/10.png"
import img11 from "../../Images/Gallery/11.png"
import img12 from "../../Images/Gallery/12.png"
import img13 from "../../Images/Gallery/13.png"
import img14 from "../../Images/Gallery/14.png"
import img15 from "../../Images/Gallery/15.png"
import img16 from "../../Images/Gallery/16.png"
import img17 from "../../Images/Gallery/17.png"
import { useRef } from 'react';

const Gallery = () => {

   const cardData = [
      {
         img: img1
      },
      {
         img: img2
      },
      {
         img: img3
      },
      {
         img: img4
      },
      {
         img: img5
      },
      {
         img: img6
      },
      {
         img: img7
      },
      {
         img: img8
      },
      {
         img: img9
      },
      {
         img: img10
      },
      {
         img: img11
      },
      {
         img: img12
      },
      {
         img: img13
      },
      {
         img: img14
      },
      {
         img: img15
      },
      {
         img: img16
      },
      {
         img: img17
      },
   ]
   const ref = useRef();

   const handleClick = () => {
      ref.current.scrollIntoView({ behavior: "smooth" });
      console.log(ref.current.scrollIntoView({ behavior: "smooth" }))
   };

   const [count, setCount] = useState(0);
   const [{ items }, setItems] = useState({ items: [] });
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => {
         window.removeEventListener("resize", handleResize);
      };

   }, []);
   useEffect(() => {
      if (items == '')
         addItem();
   }, [])
   const addItem = () => {
      var respCount = windowWidth > 768 ? 17 : 3;
      var cardLength = count + respCount;

      if (count === cardData.length) return;
      for (var i = count; i < cardLength; i++) {
         items.push(
            <div className=" p-0 col-12 col-lg-4 card-grid">
               <img className="card-img-wrapper" src={cardData[i].img} alt="gallery" />
            </div>
         )
      }
      setItems({ items: [...items] });
      setCount(cardLength);
   };


   return (
      <>
         <div id="gallery" className='my-5 d-flex align-items-center justify-content-center px-0 px-sm-5 mx-3 mx-md-5 section-8'>
            <h4 className=' mt-0 mt-md-5 text-center'>
            Every Frame Tells a Tale</h4>
         </div>
         <div className="my-5">
            <div className="img-gallery col-12  col-sm-8 col-md-9 mx-auto text-center">
               <div className="px-4 px-md-0">
                  <div ref={ref} className='img-scroll row'>
                     {/* <div className=" p-0 col-12 col-lg-4 ">
                        <img className="" src={img1} alt="gallery" />
                        <img className="  " src={img5} alt="gallery" />
                        <img className="" src={img7} alt="gallery" />
                     </div>
                     <div className=" p-0  col-12 col-lg-4">
                        <img className="" src={img2} alt="gallery" />
                        <img className="" src={img4} alt="gallery" />
                        <img className="" src={img8} alt="gallery" />
                     </div>
                     <div className=" p-0  col-12 col-lg-4">
                        <img className="" src={img3} alt="gallery" />
                        <img className=" " src={img6} alt="gallery" />
                        <img className="" src={img9} alt="gallery" />
                     </div> */}
                     {items}
                  </div>

               </div>

            </div>
            <div style={{ paddingTop: '3%', textAlign: 'center' }}>

               <button onClick={addItem} id="scroll-btn" className="know_btn mb-5">Know More</button>
            </div>

         </div>
      </>
   )
}

export default Gallery