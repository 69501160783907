import React, { useEffect, useState } from "react";
import Imgbg from '../../Images/hero/4.png'
import PhoneNumberInput from '../PhoneNumberInput'

const Form = () => {
    return (
        <form>
            <div class="form-group">
                <label for="exampleInputEmail1">Your Name</label>
                <input type="name" class="form-control" placeholder="Enter Your Name" required/>
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Your Email</label>
                <input type="email" class="form-control" placeholder="Enter Your Email" required/>
            </div>
            <div class="form-group">
                <PhoneNumberInput />
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Your Configuration</label>
                <input type="name" class="form-control" placeholder="Enter Your Query" required/>
            </div>
            <button type="submit" class="site_btn col-12  col-md-4">Submit</button>
        </form>
    )
}

const SiteForm = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
        window.removeEventListener("resize", handleResize);
    };
}, []);
    return (
        <div id="" className=' site_main pb-0'>
            {windowWidth > 768 ? (
                <div className="container site_content">

                    <div className="site_left">
                        <h4 className=''>BOOK A SITE VISIT</h4>
                        <Form />
                    </div>
                    <div className="site_right">
                        <img src={Imgbg} alt="" />
                    </div>
                </div>
            ) : (
                <div className="container site_content">
                    <h4 className=''>BOOK A SITE VISIT</h4>
                    <div className="site_right">
                        <img src={Imgbg} alt="" />
                    </div>
                    <div className="site_left">
                        <Form />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SiteForm